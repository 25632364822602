// External Dependencies
import React from 'react'

// Local Variables
const cls2Styles = {
  fill: '#382468',
};

const greenStyles = {
  fill: '#69be5f',
  transform: 'translate(1.5px, 1.5px)',
}

// Component Definition
const DsLogo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" style={props.svgStyles} viewBox="0 0 300 48">
    <title>Drumsensei Logo</title>
    <g id="Green_BG" data-name="Green BG">
      <path
        style={greenStyles}
        d="M16.51,25.94h-.3A8.44,8.44,0,0,1,8,31.38c-5.1,0-7.31-3.49-7.31-9,0-6.72,3.56-9.94,8.55-9.94,3.53,0,5.66,1.2,6.9,3.11l.3-.11c0-1.28-.11-2.52-.18-3.72s-.57-1.46-1.62-1.46a18.55,18.55,0,0,0-2,.11,45.55,45.55,0,0,0,0-4.68c2.82.07,7.28,0,9.87-.19,0,1.31-.23,7.09-.23,18.11,0,1.28.38,1.95,1.73,1.95a5.1,5.1,0,0,0,1-.11c0,1.88,0,3.79,0,5.55a67.88,67.88,0,0,0-8.32,0C16.62,29.31,16.58,27.63,16.51,25.94Zm-5-8.07c-3.22,0-4.54,1.65-4.54,3.94s.94,3.49,3.87,3.49c3.37,0,5.55-1.95,5.55-4.73C16.36,18.55,14.48,17.87,11.48,17.87Z"
      />
      <path
        style={greenStyles}
        d="M28.36,31a49.82,49.82,0,0,0,0-5.74,18.27,18.27,0,0,0,2.63.12c1.05,0,1.46-.3,1.61-1.69a28.77,28.77,0,0,0,.08-4.39c-.08-1.28-.57-1.46-1.62-1.46-1.23,0-1.87.07-2.62.11a49.84,49.84,0,0,0,0-5.74,57.76,57.76,0,0,0,9.37-.34c0,.75,0,3.38.3,5.82h.15c2-4.28,5.37-6,8.33-6,3.75,0,5.25,2.77,5.25,6.45,0,3.15-1.12,5.89-3.94,5.89C46.4,24,45,23.28,45,21.36c0-1.69,1.08-3,3.3-3.26v-.26a5.28,5.28,0,0,0-1.05-.12c-4.65,0-8.89,2.82-8.89,6,0,1,.34,1.69,1.5,1.69,2.25,0,4.35,0,5.21-.12a42.29,42.29,0,0,0,0,5.74C42.8,30.85,30.43,30.81,28.36,31Z"
      />
      <path
        style={greenStyles}
        d="M77.76,23.88c.08,1.23.56,1.42,1.61,1.42.75,0,1.28,0,1.69-.07a36.94,36.94,0,0,0,0,5.77c-2.44-.07-6.23,0-8.44,0,0-.52,0-3-.07-5.4h-.3a8.66,8.66,0,0,1-8.59,5.78c-3.86,0-5.89-2.07-5.89-5.37,0-1.87.11-4.05.11-6.22,0-1.28-.41-1.95-1.5-1.95a6.5,6.5,0,0,0-1.31.07,29,29,0,0,0,0-5.74c2.66.12,6.19.15,9.08,0C64,15.44,64,20.91,64,22.83a2.58,2.58,0,0,0,2.92,2.88c2.89,0,5.07-2.32,5.07-6,0-1.28-.38-1.95-1.92-1.95-.82,0-1.8.07-2.21.11.08-1.91.08-3.94,0-5.74,1.35.08,8.85.08,10.09,0C77.72,15.66,77.57,20.2,77.76,23.88Z"
      />
      <path
        style={greenStyles}
        d="M82.6,31c.11-1.84.08-4.35,0-5.62,1.5,0,1.8-.19,1.87-1.43s0-3.45,0-4.65S84.06,18,82.6,18.21a56.51,56.51,0,0,0-.07-6,52.3,52.3,0,0,0,6.86,0c0,1.09,0,2.82,0,4.62h.3c.86-2.89,2.25-5.07,5-5.07,2.52,0,3.45,1.92,3.57,5.07h.3c.93-3,2.32-5.07,5.14-5.07,2.55,0,3.63,2.07,3.63,5.33,0,1.39-.07,2.89-.07,6.86,0,1.47.3,1.65,1.61,1.54a27.08,27.08,0,0,0,0,5.55c-2.32-.07-4.76-.11-7.16,0,.18-3.37.18-10.46.11-12.45,0-.94-.41-1.5-1.24-1.5s-1.72.52-2,2.74a85.56,85.56,0,0,0,0,11.21A48.75,48.75,0,0,0,93,31c.15-2.17.19-10.65.19-12.64,0-.82-.34-1.31-1.24-1.31-1.12,0-1.87,1-2.13,2.77-.27,2.07-.23,8.86-.15,11.18C88.9,30.93,83.28,31,82.6,31Z"
      />
      <path
        style={greenStyles}
        d="M122.36,23.65c-6-.79-8.59-2.55-8.59-5.85,0-3.45,2.67-6,10.17-6,6.07,0,8.89,2.29,8.89,5,0,1.35-.9,2.44-3.12,2.44-2,0-3.22-1-3.22-2.29a1.67,1.67,0,0,1,0-.38c-.75-.07-1.61-.11-2.55-.11-4,0-4.83.53-4.83,1.28s.63,1.2,5.85,1.8c6.19.71,9,2.21,9,5.85s-2.81,6.15-9.68,6.15c-8.59,0-12-1.84-12-5.1,0-1.76,1.28-3,3.49-3,1.76,0,3,.86,3,2.66v0a24.26,24.26,0,0,0,5.21.49c2.85,0,4.28-.34,4.28-1.2S127.31,24.29,122.36,23.65Z"
      />
      <path
        style={greenStyles}
        d="M151.84,31.38c-8.73,0-12.37-4-12.37-9.75s3.86-9.83,12-9.83c6.68,0,11,2.17,11,8.25a23.9,23.9,0,0,1-.15,3.08c-4.69,0-12,0-16.35.11.56,2.06,2.43,3.11,5.88,3.11,4,0,6.57-.9,7.66-1.65.63,1.39,1.35,3,2.21,4.76A26.07,26.07,0,0,1,151.84,31.38Zm-6-11.18c2.67,0,6.87-.08,10.28-.15.19-2.85-1.69-3.71-4.54-3.71C148.32,16.34,146.37,17.61,145.84,20.2Z"
      />
      <path
        style={greenStyles}
        d="M190.82,25.34a6.1,6.1,0,0,0,1.31-.08,29,29,0,0,0,0,5.74c-2.66-.11-6.19-.15-9.08,0,.19-3.26.19-8.74.19-10.65a2.59,2.59,0,0,0-2.93-2.89c-2.88,0-5.06,2.33-5.06,6,0,1.27.38,1.95,1.91,1.95.83,0,1.8-.08,2.22-.12-.08,1.92-.08,3.94,0,5.74-1.8-.11-11.63-.11-13.28,0,.07-1.84.07-4.27,0-5.74a16.4,16.4,0,0,0,1.65.08c1.05,0,1.61-.3,1.69-1.65s.07-3,0-4.39-.57-1.43-1.62-1.43a16.43,16.43,0,0,0-1.68.08,37.07,37.07,0,0,0,0-5.78c2.43.12,6.22,0,8.44,0,0,.53.07,3,.18,5.4H175a8.51,8.51,0,0,1,8.51-5.77c3.86,0,5.89,2.06,5.89,5.36,0,1.88-.11,4.05-.11,6.23C189.32,24.66,189.73,25.34,190.82,25.34Z"
      />
      <path
        style={greenStyles}
        d="M205.63,23.65c-6-.79-8.59-2.55-8.59-5.85,0-3.45,2.67-6,10.17-6,6.07,0,8.89,2.29,8.89,5,0,1.35-.9,2.44-3.12,2.44-1.95,0-3.22-1-3.22-2.29a1.26,1.26,0,0,1,0-.38c-.75-.07-1.62-.11-2.55-.11-4,0-4.84.53-4.84,1.28s.64,1.2,5.85,1.8c6.19.71,9,2.21,9,5.85s-2.81,6.15-9.68,6.15c-8.59,0-12-1.84-12-5.1,0-1.76,1.28-3,3.49-3,1.77,0,3,.86,3,2.66v0a24.37,24.37,0,0,0,5.22.49c2.85,0,4.27-.34,4.27-1.2S210.58,24.29,205.63,23.65Z"
      />
      <path
        style={greenStyles}
        d="M235.12,31.38c-8.74,0-12.38-4-12.38-9.75s3.86-9.83,12-9.83c6.68,0,11,2.17,11,8.25a23.9,23.9,0,0,1-.15,3.08c-4.69,0-12,0-16.35.11.56,2.06,2.44,3.11,5.89,3.11,4,0,6.56-.9,7.65-1.65.64,1.39,1.35,3,2.21,4.76A26,26,0,0,1,235.12,31.38Zm-6-11.18c2.66,0,6.86-.08,10.27-.15.19-2.85-1.69-3.71-4.54-3.71C231.59,16.34,229.64,17.61,229.12,20.2Z"
      />
      <path
        style={greenStyles}
        d="M266.92,25.38c3.15,0,5.7,0,6.94-.12a36.65,36.65,0,0,0,0,5.74c-3.07-.19-19.8-.19-22.58,0a45.7,45.7,0,0,0,0-5.74c1.84.08,3.45.12,6.53.12,1.05,0,1.35-.27,1.5-1.65a30.17,30.17,0,0,0,.15-4.47c0-1.27-.52-1.46-1.57-1.46-3.08,0-4.62,0-6.53.11a49.84,49.84,0,0,0,0-5.74c4.09.15,10.46.12,14.1,0,0,.79-.26,4.39-.26,11.26C265.2,24.7,265.57,25.38,266.92,25.38Zm-4.31-15.31c-3.45,0-4.88-2-4.88-4.57S259.46,1,262.61,1s4.8,1.92,4.8,4.47A4.42,4.42,0,0,1,262.61,10.07Z"
      />
    </g>
    <g id="Purple_BG" data-name="Purple BG">
      <path
        style={cls2Styles}
        d="M16.51,25.94h-.3A8.44,8.44,0,0,1,8,31.38c-5.1,0-7.31-3.49-7.31-9,0-6.72,3.56-9.94,8.55-9.94,3.53,0,5.66,1.2,6.9,3.11l.3-.11c0-1.28-.11-2.52-.18-3.72s-.57-1.46-1.62-1.46a18.55,18.55,0,0,0-2,.11,45.55,45.55,0,0,0,0-4.68c2.82.07,7.28,0,9.87-.19,0,1.31-.23,7.09-.23,18.11,0,1.28.38,1.95,1.73,1.95a5.1,5.1,0,0,0,1-.11c0,1.88,0,3.79,0,5.55a67.88,67.88,0,0,0-8.32,0C16.62,29.31,16.58,27.63,16.51,25.94Zm-5-8.07c-3.22,0-4.54,1.65-4.54,3.94s.94,3.49,3.87,3.49c3.37,0,5.55-1.95,5.55-4.73C16.36,18.55,14.48,17.87,11.48,17.87Z"
      />
      <path
        style={cls2Styles}
        d="M28.36,31a49.82,49.82,0,0,0,0-5.74,18.27,18.27,0,0,0,2.63.12c1.05,0,1.46-.3,1.61-1.69a28.77,28.77,0,0,0,.08-4.39c-.08-1.28-.57-1.46-1.62-1.46-1.23,0-1.87.07-2.62.11a49.84,49.84,0,0,0,0-5.74,57.76,57.76,0,0,0,9.37-.34c0,.75,0,3.38.3,5.82h.15c2-4.28,5.37-6,8.33-6,3.75,0,5.25,2.77,5.25,6.45,0,3.15-1.12,5.89-3.94,5.89C46.4,24,45,23.28,45,21.36c0-1.69,1.08-3,3.3-3.26v-.26a5.28,5.28,0,0,0-1.05-.12c-4.65,0-8.89,2.82-8.89,6,0,1,.34,1.69,1.5,1.69,2.25,0,4.35,0,5.21-.12a42.29,42.29,0,0,0,0,5.74C42.8,30.85,30.43,30.81,28.36,31Z"
      />
      <path
        style={cls2Styles}
        d="M77.76,23.88c.08,1.23.56,1.42,1.61,1.42.75,0,1.28,0,1.69-.07a36.94,36.94,0,0,0,0,5.77c-2.44-.07-6.23,0-8.44,0,0-.52,0-3-.07-5.4h-.3a8.66,8.66,0,0,1-8.59,5.78c-3.86,0-5.89-2.07-5.89-5.37,0-1.87.11-4.05.11-6.22,0-1.28-.41-1.95-1.5-1.95a6.5,6.5,0,0,0-1.31.07,29,29,0,0,0,0-5.74c2.66.12,6.19.15,9.08,0C64,15.44,64,20.91,64,22.83a2.58,2.58,0,0,0,2.92,2.88c2.89,0,5.07-2.32,5.07-6,0-1.28-.38-1.95-1.92-1.95-.82,0-1.8.07-2.21.11.08-1.91.08-3.94,0-5.74,1.35.08,8.85.08,10.09,0C77.72,15.66,77.57,20.2,77.76,23.88Z"
      />
      <path
        style={cls2Styles}
        d="M82.6,31c.11-1.84.08-4.35,0-5.62,1.5,0,1.8-.19,1.87-1.43s0-3.45,0-4.65S84.06,18,82.6,18.21a56.51,56.51,0,0,0-.07-6,52.3,52.3,0,0,0,6.86,0c0,1.09,0,2.82,0,4.62h.3c.86-2.89,2.25-5.07,5-5.07,2.52,0,3.45,1.92,3.57,5.07h.3c.93-3,2.32-5.07,5.14-5.07,2.55,0,3.63,2.07,3.63,5.33,0,1.39-.07,2.89-.07,6.86,0,1.47.3,1.65,1.61,1.54a27.08,27.08,0,0,0,0,5.55c-2.32-.07-4.76-.11-7.16,0,.18-3.37.18-10.46.11-12.45,0-.94-.41-1.5-1.24-1.5s-1.72.52-2,2.74a85.56,85.56,0,0,0,0,11.21A48.75,48.75,0,0,0,93,31c.15-2.17.19-10.65.19-12.64,0-.82-.34-1.31-1.24-1.31-1.12,0-1.87,1-2.13,2.77-.27,2.07-.23,8.86-.15,11.18C88.9,30.93,83.28,31,82.6,31Z"
      />
      <path
        style={cls2Styles}
        d="M122.36,23.65c-6-.79-8.59-2.55-8.59-5.85,0-3.45,2.67-6,10.17-6,6.07,0,8.89,2.29,8.89,5,0,1.35-.9,2.44-3.12,2.44-2,0-3.22-1-3.22-2.29a1.67,1.67,0,0,1,0-.38c-.75-.07-1.61-.11-2.55-.11-4,0-4.83.53-4.83,1.28s.63,1.2,5.85,1.8c6.19.71,9,2.21,9,5.85s-2.81,6.15-9.68,6.15c-8.59,0-12-1.84-12-5.1,0-1.76,1.28-3,3.49-3,1.76,0,3,.86,3,2.66v0a24.26,24.26,0,0,0,5.21.49c2.85,0,4.28-.34,4.28-1.2S127.31,24.29,122.36,23.65Z"
      />
      <path
        style={cls2Styles}
        d="M151.84,31.38c-8.73,0-12.37-4-12.37-9.75s3.86-9.83,12-9.83c6.68,0,11,2.17,11,8.25a23.9,23.9,0,0,1-.15,3.08c-4.69,0-12,0-16.35.11.56,2.06,2.43,3.11,5.88,3.11,4,0,6.57-.9,7.66-1.65.63,1.39,1.35,3,2.21,4.76A26.07,26.07,0,0,1,151.84,31.38Zm-6-11.18c2.67,0,6.87-.08,10.28-.15.19-2.85-1.69-3.71-4.54-3.71C148.32,16.34,146.37,17.61,145.84,20.2Z"
      />
      <path
        style={cls2Styles}
        d="M190.82,25.34a6.1,6.1,0,0,0,1.31-.08,29,29,0,0,0,0,5.74c-2.66-.11-6.19-.15-9.08,0,.19-3.26.19-8.74.19-10.65a2.59,2.59,0,0,0-2.93-2.89c-2.88,0-5.06,2.33-5.06,6,0,1.27.38,1.95,1.91,1.95.83,0,1.8-.08,2.22-.12-.08,1.92-.08,3.94,0,5.74-1.8-.11-11.63-.11-13.28,0,.07-1.84.07-4.27,0-5.74a16.4,16.4,0,0,0,1.65.08c1.05,0,1.61-.3,1.69-1.65s.07-3,0-4.39-.57-1.43-1.62-1.43a16.43,16.43,0,0,0-1.68.08,37.07,37.07,0,0,0,0-5.78c2.43.12,6.22,0,8.44,0,0,.53.07,3,.18,5.4H175a8.51,8.51,0,0,1,8.51-5.77c3.86,0,5.89,2.06,5.89,5.36,0,1.88-.11,4.05-.11,6.23C189.32,24.66,189.73,25.34,190.82,25.34Z"
      />
      <path
        style={cls2Styles}
        d="M205.63,23.65c-6-.79-8.59-2.55-8.59-5.85,0-3.45,2.67-6,10.17-6,6.07,0,8.89,2.29,8.89,5,0,1.35-.9,2.44-3.12,2.44-1.95,0-3.22-1-3.22-2.29a1.26,1.26,0,0,1,0-.38c-.75-.07-1.62-.11-2.55-.11-4,0-4.84.53-4.84,1.28s.64,1.2,5.85,1.8c6.19.71,9,2.21,9,5.85s-2.81,6.15-9.68,6.15c-8.59,0-12-1.84-12-5.1,0-1.76,1.28-3,3.49-3,1.77,0,3,.86,3,2.66v0a24.37,24.37,0,0,0,5.22.49c2.85,0,4.27-.34,4.27-1.2S210.58,24.29,205.63,23.65Z"
      />
      <path
        style={cls2Styles}
        d="M235.12,31.38c-8.74,0-12.38-4-12.38-9.75s3.86-9.83,12-9.83c6.68,0,11,2.17,11,8.25a23.9,23.9,0,0,1-.15,3.08c-4.69,0-12,0-16.35.11.56,2.06,2.44,3.11,5.89,3.11,4,0,6.56-.9,7.65-1.65.64,1.39,1.35,3,2.21,4.76A26,26,0,0,1,235.12,31.38Zm-6-11.18c2.66,0,6.86-.08,10.27-.15.19-2.85-1.69-3.71-4.54-3.71C231.59,16.34,229.64,17.61,229.12,20.2Z"
      />
      <path
        style={cls2Styles}
        d="M266.92,25.38c3.15,0,5.7,0,6.94-.12a36.65,36.65,0,0,0,0,5.74c-3.07-.19-19.8-.19-22.58,0a45.7,45.7,0,0,0,0-5.74c1.84.08,3.45.12,6.53.12,1.05,0,1.35-.27,1.5-1.65a30.17,30.17,0,0,0,.15-4.47c0-1.27-.52-1.46-1.57-1.46-3.08,0-4.62,0-6.53.11a49.84,49.84,0,0,0,0-5.74c4.09.15,10.46.12,14.1,0,0,.79-.26,4.39-.26,11.26C265.2,24.7,265.57,25.38,266.92,25.38Zm-4.31-15.31c-3.45,0-4.88-2-4.88-4.57S259.46,1,262.61,1s4.8,1.92,4.8,4.47A4.42,4.42,0,0,1,262.61,10.07Z"
      />
    </g>
  </svg>
)

export default DsLogo;
